































































































































































































































































































































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BFormCheckbox,
  BPagination,
  BRow,
  BTable,
  BBadge,
  BAlert,
  BFormGroup,
  BFormDatepicker,
  BForm
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { DetailsUtilisateur } from "@/api/models/utilisateurs/utilisateur";
import { Entreprise } from "@/api/models/adherents/adherent";
import { Prestation, PrestationSite } from "@/api/models/prestations/prestation";
import { Site } from "@/api/models/sites/site";
import { TypeCreneau } from "@/api/models/creneaux/typeCreneau";
import { Creneau, ExportCreneauxSearchModel } from "@/api/models/creneaux/creneau";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { ExportPlanningAdherentModele } from "@/api/models/creneaux/exportPlanningAdherentModele";

@Component({
  components: {
    BCard,
    BAlert,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormCheckbox,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BBadge,
    SearchableVueSelect,
    BFormGroup,
    BFormDatepicker,
    ValidationObserver,
    ValidationProvider,
    BForm
  },
})
export default class ExportPlanningAdherent extends Vue {
  refreshIndex = 0
  perPage = 10;
  pertinentOnly = false;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  timeout: any = null;
  searchQuery = "";
  isSortDirDesc = false;
  sortBy = "dateDebut";
  sortStatut: any = null
  search: ExportCreneauxSearchModel = {
    isInterval: false,
    typesCreneauIds: [],
    salariesIds: [],
    sitesIds: [],
    annee: new Date().getFullYear()
  }

  tableColumns = [
    { key: "dateDebut", label: "Date de début", sortable: true },
    { key: "dateFin", label: "Date de fin", sortable: true },
    { key: "duree", label: "Durée", sortable: true },
    { key: "typeCreneauLibelle", label: "Type", sortable: true },
    { key: "commentaire", label: "Contenu", sortable: true },
    { key: "entrepriseLibelle", label: "Société", sortable: true },
    { key: "salariesCompleteLabels", label: "Salarié(s)", sortable: true }
  ];

  listMois = [
    { value: 1, text: "Janvier"},
    { value: 2, text: "Février"},
    { value: 3, text: "Mars"},
    { value: 4, text: "Avril"},
    { value: 5, text: "Mai"},
    { value: 6, text: "Juin"},
    { value: 7, text: "Juillet"},
    { value: 8, text: "Août"},
    { value: 9, text: "Septembre"},
    { value: 10, text: "Octobre"},
    { value: 11, text: "Novembre"},
    { value: 12, text: "Décembre"}
  ];

  
  paginatedListCreneaux = new PaginatedList<Creneau>();
  listSalaries = new PaginatedList<DetailsUtilisateur>();
  listSalariesAlerter = new PaginatedList<DetailsUtilisateur>();
  listEntreprises = new PaginatedList<Entreprise>();
  listPrestations = new PaginatedList<Prestation>();
  listModeles = new PaginatedList<ExportPlanningAdherentModele>();
  listTypesCreneau = new PaginatedList<TypeCreneau>();
  listSites: PrestationSite[] = [];

  async created() {
    await this.searchTypesCreneau(null);
  }

  get searchDisabled() {
    return !(((this.search.annee &&
      this.search.moisDebut &&
      this.search.moisFin && !this.search.isInterval) ||
      (this.search.dateDebut && this.search.dateFin && this.search.isInterval)) &&
      this.search.entrepriseId)
  }

  get anneesOptions(): number[] {
    const anneeDebut = 2020
    const anneeFin: number = new Date().getFullYear() + 3;
    const annees: number[] = [];

    if (anneeDebut <= anneeFin) {
        for (let annee = anneeDebut; annee <= anneeFin; annee++) {
            annees.push(annee);
        }
    } else {
        console.error("L'année de début doit être inférieure ou égale à l'année actuelle.");
    }

    return annees;
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListCreneaux.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListCreneaux.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  @Watch("pertinentOnly")
  async paginationChanged() {
    await this.loadListCreneaux({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      orderBy: this.sortBy,
      orderByDescending: this.isSortDirDesc
    });
  }

  @Watch("sortBy")
  @Watch("isSortDirDesc")
  async searchCreneaux() {
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.loadListCreneaux({
        pageNumber: this.currentPage,
        pageSize: this.perPage,
        orderBy: this.sortBy,
        orderByDescending: this.isSortDirDesc
      })
    }, 1000);
  }

  updateSort(params: any) {
    this.sortBy = params.sortBy;
    params = {
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      orderBy: this.sortBy,
      orderByDescending: this.isSortDirDesc,
    };
    this.isSortDirDesc = !this.isSortDirDesc;
    this.loadListCreneaux(params);
  }

  async loadListCreneaux(params: any) {
    if (!params || params?.reset) {
      this.paginatedListCreneaux = new PaginatedList<Creneau>();
    }

    const isInterval = this.search.isInterval
    if (!isInterval) {
      this.search.dateDebut = this.search.annee + "-" + this.search.moisDebut + "-" + "01"
      var dateFin = new Date(this.search.annee!, this.search.moisFin!, 0)
      this.search.dateFin = dateFin.getFullYear() + "-" + (dateFin.getMonth() + 1) + "-" + dateFin.getDate()
    }

    await this.$http.myressif.creneaux
      .paginatedList(
        this.search.dateDebut,
        this.search.dateFin,
        this.search.entrepriseId,
        "",
        this.search.salariesIds,
        [],
        false,
        this.search.typesCreneauIds,
        this.search.sitesIds,
        this.search.prestationId,
        !isInterval ? this.search.annee : null,
        !isInterval ? this.search.moisDebut : null,
        !isInterval ? this.search.moisFin : null,
        this.search.search,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.orderBy,
        params?.orderByDescending
      )
      .then((response: PaginatedList<Creneau>) => {
        this.paginatedListCreneaux = response
      }
    );
  }


  async exportPlanning() {
    const isInterval = this.search.isInterval
    if (!isInterval) {
      this.search.dateDebut = this.search.annee + "-" + this.search.moisDebut + "-" + "01"
      var dateFin = new Date(this.search.annee!, this.search.moisFin!, 0)
      this.search.dateFin = dateFin.getFullYear() + "-" + (dateFin.getMonth() + 1) + "-" + dateFin.getDate()
    }

    await this.$http.myressif.creneaux
      .exportPlanningAdherent(
        this.search.dateDebut,
        this.search.dateFin,
        this.search.entrepriseId,
        "",
        this.search.salariesIds,
        [],
        false,
        this.search.typesCreneauIds,
        this.search.sitesIds,
        this.search.prestationId,
        this.search.modeleId,
        !isInterval ? this.search.annee : null,
        !isInterval ? this.search.moisDebut : null,
        !isInterval ? this.search.moisFin : null,
        this.search.search,
        null,
        null,
        null,
        null
      )
      .then(
        async (response: any) => {
          var blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          var url = window.URL.createObjectURL(blob);
          var link = document.createElement("a");
          link.href = url;

          var date = new Date();

          link.setAttribute(
            "download",
            date.getDate() +
              "/" +
              (date.getMonth() + 1) +
              "/" +
              date.getFullYear() +
              "_" +
              date.getTime() +
              ".xlsx"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
  }

  async exportPlanningExcel() {
    const isInterval = this.search.isInterval
    if (!isInterval) {
      this.search.dateDebut = this.search.annee + "-" + this.search.moisDebut + "-" + "01"
      var dateFin = new Date(this.search.annee!, this.search.moisFin!, 0)
      this.search.dateFin = dateFin.getFullYear() + "-" + (dateFin.getMonth() + 1) + "-" + dateFin.getDate()
    }

    await this.$http.myressif.creneaux
      .exportPlanningExcel(
        this.search.dateDebut,
        this.search.dateFin,
        this.search.entrepriseId,
        "",
        this.search.salariesIds,
        [],
        false,
        this.search.typesCreneauIds,
        this.search.sitesIds,
        this.search.prestationId,
        this.search.modeleId,
        !isInterval ? this.search.annee : null,
        !isInterval ? this.search.moisDebut : null,
        !isInterval ? this.search.moisFin : null,
        this.search.search,
        null,
        null,
        null,
        null
      )
      .then(
        async (response: any) => {
          var blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          var url = window.URL.createObjectURL(blob);
          var link = document.createElement("a");
          link.href = url;

          var date = new Date();

          link.setAttribute(
            "download",
            date.getDate() +
              "/" +
              (date.getMonth() + 1) +
              "/" +
              date.getFullYear() +
              "_" +
              date.getTime() +
              ".xlsx"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
  }

  async searchModeles(params: any) {
    if (!params || params?.reset) {
      this.listModeles = new PaginatedList<ExportPlanningAdherentModele>();
    }
    await this.$http.myressif.exportPlanningAdherentModeles
      .paginatedList(params?.search, params?.pageNumber ?? 1, params?.pageSize ?? 10)
      .then((response: PaginatedList<ExportPlanningAdherentModele>) => {
        this.listModeles = response;
      });
  }

  async searchSalaries(params: any) {
    await this.$http.myressif.utilisateurs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<DetailsUtilisateur>) => {
        this.listSalaries.items = this.listSalaries!.items.concat(
            response.items.filter(
              (ri) => !this.listSalaries!.items.some((lsi) => lsi.id == ri.id)
            )
          ).map((x: DetailsUtilisateur) => <DetailsUtilisateur>{
          ...x,
          completeLabel: `${x.nom} ${x.prenom}`
        });
        this.listSalaries.hasNextPage = response.hasNextPage
        this.listSalaries.hasPreviousPage = response.hasPreviousPage
        this.listSalaries.pageIndex = response.pageIndex
        this.listSalaries.totalCount = response.totalCount
        this.listSalaries.totalPages = response.totalPages
      });
  }

  async searchSalariesAlerter(params: any) {
    await this.$http.myressif.utilisateurs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<DetailsUtilisateur>) => {
        this.listSalariesAlerter.items = this.listSalariesAlerter!.items.concat(
            response.items.filter(
              (ri) => !this.listSalariesAlerter!.items.some((lsi) => lsi.id == ri.id)
            )
          ).map((x: DetailsUtilisateur) => <DetailsUtilisateur>{
          ...x,
          completeLabel: `${x.nom} ${x.prenom}`
        });
        this.listSalariesAlerter.hasNextPage = response.hasNextPage
        this.listSalariesAlerter.hasPreviousPage = response.hasPreviousPage
        this.listSalariesAlerter.pageIndex = response.pageIndex
        this.listSalariesAlerter.totalCount = response.totalCount
        this.listSalariesAlerter.totalPages = response.totalPages
      });
  }

  async searchEntreprises(params: any) {
    await this.$http.myressif.entreprises
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.orderBy,
        params?.orderByDescending,
        params?.search ?? "",
        params?.pertinentOnly,
        params?.sortStatut
      )
      .then((response: PaginatedList<Entreprise>) => {
        this.listEntreprises.items = this.listEntreprises!.items.concat(
            response.items.filter(
              (ri) => !this.listEntreprises!.items.some((lsi) => lsi.id == ri.id)
            )
          );
        this.listEntreprises.hasNextPage = response.hasNextPage
        this.listEntreprises.hasPreviousPage = response.hasPreviousPage
        this.listEntreprises.pageIndex = response.pageIndex
        this.listEntreprises.totalCount = response.totalCount
        this.listEntreprises.totalPages = response.totalPages
      });
  }

  async searchPrestationsByEntrepriseId(params: any, entrepriseId: string) {
    if (!params || params?.reset) {
      this.listPrestations = new PaginatedList<Prestation>();
      this.search.prestationId = undefined
      this.search.sitesIds = []
      this.refreshIndex++
    }
    await this.$http.myressif.entreprises
      .getListPrestationsByEntrepriseId(
        entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<Prestation>) => {
        this.listPrestations = response;
      });
  }

  async searchTypesCreneau(params: any) {
    if (!params || params?.reset) {
      this.listTypesCreneau = new PaginatedList<TypeCreneau>();
    }
    await this.$http.myressif.typesCreneau
      .paginatedList(
        params?.search ?? "",
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
      )
      .then((response: PaginatedList<TypeCreneau>) => {
        this.listTypesCreneau = response;
      });
  }

  async searchSitesByPrestationId(params: any, entrepriseId: string, prestationId: string) {
    if (!params || params?.reset) {
      this.listSites = [];
    }

    await this.$http.myressif.entreprises
      .getListPrestationsSitesByPrestationId(entrepriseId, prestationId, null)
      .then(async (res: PrestationSite[]) => {
        this.listSites = res;
      })
  }
}

